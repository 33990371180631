function Info() {
    return (
        <section id="about" className="about" style={{ marginTop: "100px" }}>
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>About Us</h2>
                </div>

                <div className="row">
                    <div className="col-lg-6" data-aos="fade-right">
                        <img src="pics/aboutHero.jpeg" className="img-fluid" alt="NewLook Al Ain AlKhabisi" />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
                        <h3>Welcome to Newlook Medical Center – Your Health, Our Vision</h3>
                        {/* <p className="fst-italic">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua.
                        </p>
                        <ul>
                            <li><i className="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                            <li><i className="bi bi-check-circle"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                            <li><i className="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                        </ul> */}
                        <p>
                            Newlook Medical Center is a beacon of healthcare excellence in Al Ain, renowned for providing superior medical services with a patient-centered approach. We’re proud to be the leading medical center in the region with three state-of-the-art branches that cater to the diverse needs of our community.
                        </p>
                        <p>
                            For appointments and inquiries, please contact us or click the make appointment. We look forward to becoming your trusted partner in health.                        </p>
                    </div>
                </div>

            </div>
        </section>
    )
}
export default Info