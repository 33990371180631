import DoctorCard from "../DoctorCard/DoctorCard";
import { Container, Row, Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap
import Form from 'react-bootstrap/Form';
import React, { useState } from 'react';


function DoctorsList() {

    const allDoctors = [
        { id: 1, name: 'Dr. Mamoun Al Athamneh', image: '/Doctors/Athamneh.JPG', branch: "Al Yahar" , speciality: 'Consultant Dermatology', languages: ['Arabic', 'English'] },
        { id: 2, name: 'Dr. Mohamed Saad Alsebai', image: '/Doctors/Alsebai.jpeg', branch: "Al Yahar" , speciality: 'Specialist Otolaryngology', languages: ['Arabic', 'English'] },
        { id: 3, name: 'Dr. Abdalla Eltayeb', image: '/Doctors/Eltayeb.JPG', branch: "Al Yahar" , speciality: 'Specialist Pediatrics', languages: ['Arabic', 'English'] },
        { id: 4, name: 'Dr. Jeehan Mohammed', image: '/Doctors/Jeehan.JPG', branch: "Al Yahar" , speciality: 'General Practitioner', languages: ['Arabic', 'English'] },
        { id: 5, name: 'Dr. Ali Sabouh', image: '/Doctors/Sabouh.jpg', branch: "Al Yahar" , speciality: 'Specialist Oral & Maxillofacial Surgery', languages: ['Arabic', 'English'] },
        { id: 6, name: 'Dr. Mohamed Anwar', image: '/Doctors/Anwar.jpg', branch: "Al Yahar" , speciality: 'Specialist Orthodontics', languages: ['Arabic', 'English'] },
        // { id: 7, name: 'Dr. Deepthi Puliyulla', image: '/Doctors/Puliyulla.JPG', branch: "Al Yahar" , speciality: 'Specialist Periodontics', languages: ['Arabic', 'English'] },
        { id: 8, name: 'Dr. Ehab Tageldin', image: '/Doctors/Tageldin.jpg', branch: "Al Yahar" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 9, name: 'Dr. Roaa Emad Yousif', image: '/Doctors/Emad.jpg', branch: "Al Yahar" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 10, name: 'Dr. Reham Ibrahim Ali', image: '/Doctors/Reham.jpg', branch: "Al Yahar" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 11, name: 'Dr. Wesam Mansour', image: '/Doctors/Wesam.jpg', branch: "AlKhabisi" , speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        { id: 12, name: 'Dr. Basel Mohammad', image: '/Doctors/Basel.jpg', branch: "AlKhabisi" , speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        { id: 13, name: 'Dr. Mifleh Khaled Ali', image: '/Doctors/Mifleh.jpg', branch: "AlKhabisi" , speciality: 'General Practitioner', languages: ['Arabic', 'English'] },
        { id: 14, name: 'Dr. Pranjal Sharma', image: '/Doctors/Pranjal.jpg', branch: "AlKhabisi" , speciality: 'Specialist Pediatric Dentistry', languages: ['Arabic', 'English'] },
        { id: 15, name: 'Dr. Adham Abu Kharroub', image: '/Doctors/Kharroub.jpg', branch: "AlKhabisi" , speciality: 'Specialist Orthodontics', languages: ['Arabic', 'English'] },
        { id: 16, name: 'Dr. Al Thanzeer Usman', image: '/Doctors/Usman.jpg', branch: "AlKhabisi" , speciality: 'Specialist Endodontics', languages: ['Arabic', 'English'] },
        { id: 17, name: 'Dr. Ahmed Khairi Izzat', image: '/Doctors/Izzat.jpg', branch: "AlKhabisi" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 18, name: 'Dr. Manar Abdul Fattah', image: '/Doctors/Fattah.jpg', branch: "AlKhabisi" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 19, name: 'Dr. Mohamad Termanini', image: '/Doctors/Termanini.jpg', branch: "AlKhabisi" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 20, name: 'Dr. Abdulrahman Lbabidi', image: '/Doctors/Lbabidi.jpg', branch: "AlKhabisi" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 21, name: 'Dr. Reiof Aman Hathat', image: '/Doctors/Reiof.jpg', branch: "AlKhabisi" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 22, name: 'Dr. Ahmed H S Ghazal', image: '/Doctors/Ghazal.jpg', branch: "AlKhabisi" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 23, name: 'Dr. Sami Owais', image: '/Doctors/Owais.jpg', branch: "Souq Extra" , speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        { id: 24, name: 'Dr. Osama H.M Qasrawi', image: '/Doctors/Qasrawi.jpg', branch: "Souq Extra" , speciality: 'Specialist Orthodontics', languages: ['Arabic', 'English'] },
        { id: 25, name: 'Dr. Banan Shurrab', image: '/Doctors/Shurrab.jpg', branch: "Souq Extra" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 26, name: 'Dr. Huria Jarusheh', image: '/Doctors/Jarusheh.jpg', branch: "Souq Extra" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 27, name: 'Dr. Hind Hasan', image: '/Doctors/Hind.jpg', branch: "Souq Extra" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 28, name: 'Dr. Mohamed Hassan', image: '/Doctors/Mohamed.jpg', branch: "Souq Extra" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 29, name: 'Dr. Abdulrahman Mohammed', image: '/Doctors/Abdulrahman.jpg', branch: "Souq Extra" , speciality: 'General Dentist', languages: ['Arabic', 'English'] },

        

    ];

    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');

    const specialities = Array.from(new Set(allDoctors.map((doctor) => doctor.speciality)));

    const filteredDoctors = allDoctors.filter((doctor) => {
        return (!selectedSpeciality || doctor.speciality === selectedSpeciality) &&
            (selectedLanguages.length === 0 ||
                selectedLanguages.includes('All') ||
                selectedLanguages.some(language => doctor.languages.includes(language))) &&
                (!selectedBranch || doctor.branch === selectedBranch);
    });

    const handleLanguageChange = (event) => {
        const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedLanguages(selectedValues);
    };

    const handleSpecialityChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSpeciality(selectedValue);
    };

    const handleBranchChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedBranch(selectedValue);
    };

    
    return (
        <section id="doctors" className="doctors section-bg" style={{ marginTop: "100px" }}>
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Doctors</h2>
                </div>

                <Row>
                    <Col lg={4}><Form.Select onChange={handleSpecialityChange} className="" aria-label="Doctor Speciality" style={{ boxShadow: "none", marginBottom: "50px", backgroundColor: "white" }}>
                        <option value="">All Specialities</option>
                        {specialities.map((speciality, index) => (
                            <option key={index} value={speciality}>
                                {speciality}
                            </option>
                        ))}
                    </Form.Select></Col>



                    <Col lg={4}><Form.Select onChange={handleLanguageChange} aria-label="Doctor Language" style={{ boxShadow: "none", marginBottom: "50px" }}>
                        <option value="All">All Language</option>
                        <option value="French">French</option>
                        <option value="English">English</option>
                        <option value="Arabic">Arabic</option>
                        <option value="Turkish">Turkish</option>
                        <option value="Urdu">Urdu</option>
                        <option value="Russian">Russian</option>
                    </Form.Select></Col>

                    <Col lg={4}><Form.Select onChange={handleBranchChange} aria-label="Doctor Branch" style={{ boxShadow: "none", marginBottom: "50px" }}>
                        <option value="">All Branches</option>
                        <option value="Al Yahar">Al Yahar</option>
                        <option value="Souq Extra">Souq Extra</option>
                        <option value="AlKhabisi">AlKhabisi</option>
                        
                    </Form.Select></Col>



                </Row>

                <div className="row">
                    {filteredDoctors.map((doctor, index) => (
                        <DoctorCard props={doctor} index={index} />
                    ))}
                </div>

            </div>
        </section>
    )
}
export default DoctorsList