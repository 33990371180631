import Slider from "../Slider/Slider"
import Branches from "../Branches/Branches"
import HomeDoctors from "../HomeDoctors/HomeDoctors"
import FeatureItem from "../FeatureItem/FeatureItem"
import FeatureList from "../FeatureList/FeatureList"
import Specialities from "../Specialities/Specialities"
function Home({ onComponentChange }) {
    return (
        <div>
            <Slider />
            <Branches onComponentChange={onComponentChange} />
            <FeatureList onComponentChange={onComponentChange}/>
            {/* <HomeDoctors /> */}
        </div>

    )


}
export default Home