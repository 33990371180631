import React, { useEffect } from 'react';
import emailjs from 'emailjs-com';
import SuccessModal from '../SuccessModal/SuccessModal';
import  { useState } from 'react';

function Contact({ branch }) {
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    function sendEmail(e) {

        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fwgrqew', 'template_p1l1t0j', e.target, 'tKbfD7Qc7HH_s5Z5R')
            .then((result) => {
                // window.location.reload()  
                DisplayPopUp()
            }, (error) => {
                console.log(error.text);
            });

    }

    function DisplayPopUp() {
        console.log("test")
        setShowSuccessMessage(true)


    }

    function closePopUp() {
        setShowSuccessMessage(false)
    }


    useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []); // Empty dependency array to run the effect only once
    return (

        <section id="contact" className="contact" style={{ marginTop: "100px" }}>
            <SuccessModal show={showSuccessMessage} handleClose={closePopUp} />

            <div className="container">
                <div className="section-title">
                    <h2>{branch.name}</h2>
                </div>
            </div>



            <div className="container" style={{ marginBottom: "50px", marginTop: "-50px" }}>
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="info-box">
                                    <i className="bx bx-map"></i>
                                    <h3>Our Address</h3>
                                    <p>{branch.address}</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-box mt-4">
                                    <i className="bx bx-envelope"></i>
                                    <h3>Email Us</h3>
                                    <p>info@newlook.ae</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-box mt-4">
                                    <i className="bx bx-phone-call"></i>
                                    <h3>Call Us</h3>
                                    <p>{branch.number}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <form onSubmit={sendEmail} role="form" className="php-email-form">
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                </div>
                                <div className="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                </div>
                                <div className="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="number" className="form-control" name="phone" id="email" placeholder="Your Number" required />
                                </div>
                                <div className="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="hidden" className="form-control" name="sendTarget" value="info@newlook.ae" id="test" placeholder="Your Phone" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                    <div className="validate"></div>
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                            </div>
                            <div className="form-group mt-3">
                                <textarea className="form-control" name="message" rows="7" placeholder="Message" required></textarea>
                            </div>
                           
                            {/* <div className="text-center" style={{marginTop: '15px'}}><button>Send Message</button></div> */}
                            <div className="text-center" style={{marginTop: "15px"}}><button type="submit">Send Message</button></div>

                        </form>

                    </div>
                </div>
            </div>

            <div>
                <iframe
                    style={{ border: '0', width: '100%', height: '350px' }}
                    src={branch.googleMaps}
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
        </section>
    )

}
export default Contact