import React, { useState } from 'react';
import Appointment from '../Appointment/Appointment';


function DoctorCard({ props, index }) {

    const [showAppointment, setShowAppointment] = useState(false);
    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);
    return (

        <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
            <div className="member" data-aos="fade-up" data-aos-delay={100 * (index + 1)}>
                <div className="member-img" style={{ maxHeight: "350px", minWidth: "300px", objectFit: "cover" }}>
                    <img src={props.image} className="img-fluid" alt={`${props.name} - ${props.specialization}`} />
                    <div className="social">
                        <a href="#" onClick={handleShowAppointment}>Make Appointment</a>

                    </div>
                </div>
                <div className="member-info">
                    <h4>{props.name}</h4>
                    <h6 style={{ color: "#D9BE8F" }}>{props.speciality}</h6>
                    <h6 style={{ color: "black" }}>{props.branch}</h6>
                </div>
            </div>
            <Appointment show={showAppointment} handleClose={handleCloseAppointment} doctor={props}/>
        </div>

    )
}
export default DoctorCard