import Info from "../Info/Info"
import Counts from "../Counts/Counts"
import Features from "../Features/Features"
import FrequentlyAsked from "../FrequentlyAsked/FrequentlyAsked"

function AboutUs({ onComponentChange }) {
    return (
        <div>
            <Info />
            <Counts onComponentChange={onComponentChange}/>
            <Features />
            {/* <FrequentlyAsked /> */}
        </div>

    )

}
export default AboutUs