import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Appointment from '../Appointment/Appointment';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaSnapchatGhost } from "react-icons/fa";
import SuccessModal from '../SuccessModal/SuccessModal';


function NavBar({ onComponentChange, activeComponent }) {
    console.log(activeComponent)
    const [activeNavItem, setActiveNavItem] = useState(activeComponent);
    const [showAppointment, setShowAppointment] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);
    const handleNavItemClick = (item, branch) => {
        activeComponent = item
        setActiveNavItem(activeComponent);
        onComponentChange(item, branch);
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    function DisplayPopUp() {
        console.log("test")
        setShowSuccessMessage(true)


    }

    function closePopUp() {
        setShowSuccessMessage(false)
    }

    const branches = [
        { id: 1, name: 'NewLook Al Ain Souq Extra', image: '/Doctors/Majzoub.jpeg', address: 'Souq Extra - Al Bateen - Al Ghadeer - Al Ain', email: ['contact@example.com', 'English'], number: "03 716 3999", googleMaps: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14554.70195729674!2d55.6406303!3d24.2181424!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8aaddd1d6142d3%3A0x885d2271b52e7122!2sNew%20look%20Medical%20Center!5e0!3m2!1sen!2sae!4v1703743830346!5m2!1sen!2sae" },
        { id: 2, name: 'NewLook Al Ain Al Yahar', image: '/Doctors/Majzoub.jpeg', address: 'Al Yahar - Al Aamerah - Al Ain', email: ['contact@example.com', 'English'], number: "03 766 6722", googleMaps: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14553.993681416607!2d55.5526466!3d24.2243404!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8aabadf7a59865%3A0x4027cc27a1a4e085!2zTmV3IExvb2sgTWVkaWNhbCBDZW50ZXIgQWwtWWFoYXIg2YbZitmI2YTZiNmDINin2YTYt9io2Yog2YHYsdi5INin2YTZitit2LE!5e0!3m2!1sen!2sae!4v1703744776907!5m2!1sen!2sae" },
        { id: 3, name: 'NewLook Al AlKhabisi', image: '/Doctors/Majzoub.jpeg', address: 'AlKhabisi - Al Ain', email: ['contact@example.com', 'English'], number: "03 763 6665", googleMaps: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14551.988067812164!2d55.7158313!3d24.2418831!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8ab3d2f4767f07%3A0xad0aa620f05f9127!2sNew%20Look%20Medical%20Center!5e0!3m2!1sen!2sae!4v1703745449830!5m2!1sen!2sae" },

    ];

    const handleButtonClick = (url) => {
        // Replace 'https://example.com' with the URL you want to open
        window.open(url, '_blank');
    };

    const renderContactList = () => {

        return (
            <ListGroup variant="flush">
                {/* Add your contact list items here */}
                {/* For example: */}
                <ListGroup.Item action >
                    <a href='#' onClick={() => handleNavItemClick('contact', branches[0])} >NewLook Al Ain Souq Extra</a>
                </ListGroup.Item>

                <ListGroup.Item action >
                    <a href='#' onClick={() => handleNavItemClick('contact', branches[1])} >NewLook Al Ain Al Yahar</a>
                </ListGroup.Item>
                <ListGroup.Item action >
                    <a href='#' onClick={() => handleNavItemClick('contact', branches[2])} >NewLook Al AlKhabisi</a>
                </ListGroup.Item>
                {/* ... add more items as needed */}
            </ListGroup>
        );

        return null; // Render nothing if the activeNavItem is not 'contact'
    };

    return (
        <div>
            <SuccessModal show={showSuccessMessage} handleClose={closePopUp} />
            <Appointment show={showAppointment} handleClose={handleCloseAppointment} showPopUp={DisplayPopUp}/>
            <div id="topbar" className="d-flex align-items-center fixed-top">
                <div className="container d-flex align-items-center justify-content-center justify-content-md-between">
                    <div className="align-items-center d-none d-md-flex">
                        <i className="bi bi-clock"></i> Saturday - Friday, 9AM to 10PM
                    </div>
                    <div className="d-flex align-items-center">
                        <a href='#' onClick={() => handleButtonClick("https://www.facebook.com/NewlookMedicalCenter/")} style={{ color: "white", marginRight: "15px" }}><FaFacebook /></a>
                        <a href='#' onClick={() => handleButtonClick("https://www.instagram.com/newlook_mc/?hl=en")} style={{ color: "white", marginRight: "15px" }}><FaInstagram /></a>
                        <a href='#' onClick={() => handleButtonClick("https://wa.me/+97137163999")} style={{ color: "white", marginRight: "15px" }}><FaWhatsapp /></a>
                        {/* <a href="#" style={{ color: "white", marginRight: "15px" }}><FaSnapchatGhost /></a> */}
                    </div>
                </div>
            </div>
            <header id="header" className="fixed-top" >
                <div className="container d-flex align-items-center">
                    <a href='#' onClick={() => handleNavItemClick('home')} className="logo me-auto"><img style={{ width: "60px", height: "100%", objectFit: "cover", zIndex: "58" }} src="Logo/NewLook.png" alt="" /></a>
                    {/* Uncomment below if you prefer to use an image logo */}
                    {/* <h1 className="logo me-auto"><a href="index.html">Medicio</a></h1> */}

                    <nav id="navbar" className="navbar order-last order-lg-0">
                        <ul>
                            <li><a href='#' onClick={() => handleNavItemClick('home')} className={`nav-link scrollto ${activeNavItem === 'home' ? 'active' : ''}`} >Home</a></li>
                            <li><a href='#' onClick={() => handleNavItemClick('about')} className={`nav-link scrollto ${activeNavItem === 'about' ? 'active' : ''}`} >About</a></li>
                            <li><a href='#' onClick={() => handleNavItemClick('Specialities')} className={`nav-link scrollto ${activeNavItem === 'Specialities' ? 'active' : ''}`} >Specialities</a></li>
                            <li><a href='#' onClick={() => handleNavItemClick('doctors')} className={`nav-link scrollto ${activeNavItem === 'doctors' ? 'active' : ''}`} >Doctors</a></li>
                            <li className="dropdown">
                                <a ><span>Our Branches</span> <i className="bi bi-chevron-down"></i></a>
                                <ul>
                                    <li><a href='#' onClick={() => handleNavItemClick('contact', branches[0])} >NewLook Al Ain Souq Extra</a></li>
                                    <li><a href='#' onClick={() => handleNavItemClick('contact', branches[1])} >NewLook Al Ain Al Yahar</a></li>
                                    <li><a href='#' onClick={() => handleNavItemClick('contact', branches[2])} >NewLook Al AlKhabisi</a></li>
                                </ul>
                            </li>
                            {/* <li><a className="nav-link scrollto" onClick={() => handleNavItemClick('contact')} href="#contact">Contact</a></li> */}
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" onClick={handleShow}></i>
                    </nav>

                    <a onClick={handleShowAppointment} href="#appointment" className="appointment-btn scrollto">
                        <span className="d-none d-md-inline">Make an</span> Appointment
                    </a>
                </div>
            </header>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup >
                        <ListGroup.Item action >
                            <a href='#' style={{ color: activeNavItem === 'home' ? "#D9BE8F" : "" }}

                                className={`nav-link scrollto ${activeNavItem === 'home' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('home')}>Home</a>
                        </ListGroup.Item>
                        <ListGroup.Item action >
                            <a href='#' style={{ color: activeNavItem === 'about' ? "#D9BE8F" : "" }} className={`nav-link scrollto ${activeNavItem === 'about' ? 'active' : ''}`} onClick={() => handleNavItemClick('about')}>About</a>
                        </ListGroup.Item>
                        <ListGroup.Item action >
                            <a href='#' style={{ color: activeNavItem === 'doctors' ? "#D9BE8F" : "" }}

                                className={`nav-link scrollto ${activeNavItem === 'doctors' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('doctors')}>Doctors</a>
                        </ListGroup.Item>
                        <ListGroup.Item action >
                            <a href='#' style={{ color: activeNavItem === 'contactMob' ? "#D9BE8F" : "" }}

                                className={`nav-link scrollto ${activeNavItem === 'contactMob' ? 'active' : ''}`}
                            >Contact</a>
                            {renderContactList()}
                        </ListGroup.Item>

                        <ListGroup.Item action >
                            <a href='#' style={{ color: activeNavItem === 'Specialities' ? "#D9BE8F" : "" }}

                                className={`nav-link scrollto ${activeNavItem === 'Specialities' ? 'active' : ''}`}
                                onClick={() => handleNavItemClick('Specialities')}>Specialities</a>
                        </ListGroup.Item>

                    </ListGroup>


                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default NavBar