import { Container, Row, Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap


function Specialities({ defaultSpeciality }) {

    const featuresData = [
        {
            imgSrc: '/services/dermatology.jpg',
            alt: 'dermatology',
            icon: 'FaRegSun',
            title: 'Dermatology',
            desc: "The New Look Dermatology department serves a wide range of services from paediatric to adult, ethnic origin, religion, social status, disease and disability. Offering easy access to the highest standard of care, the services are integrated to assure a continuum of quality and a multicultural environment."
        },
        {
            imgSrc: '/services/otolaryngology.jpg',
            alt: 'otolaryngology',
            icon: 'FaHeadSideCough',
            title: 'Otolaryngology',
            desc: "The ENT department specializes in diagnosing and treating a broad spectrum of ear, nose, and throat conditions. This includes the management of external, middle, and inner ear diseases, nasal and sinus disorders, as well as oral, pharyngeal, laryngeal, and tracheal conditions. The department utilizes advanced techniques such as nasal and flexible endoscopy for accurate diagnosis. Additionally, it conducts minor surgical procedures like abscess drainage, cerumen extraction, and removal of foreign bodies from ear canals, nasal passages, and oral cavities, emphasizing its commitment to comprehensive care for ENT-related issues."
        },
        {
            imgSrc: '/services/pediatric.jpg',
            alt: 'pediatric',
            icon: 'FaRunning',
            title: 'Pediatrics',
            desc: "The Pediatric Medical Unit serves as a specialized outpatient clinic, delivering comprehensive preventive and curative care from birth through adolescence. The unit handles newborns and children up to thirteen years, with continuity of care for adolescents with chronic conditions. Doctors are adept in evaluating and managing acute and chronic medical issues, facilitating transfers to specialized clinics or other hospitals when necessary. The unit employs common laboratory and radiological studies, conducts systemic assessments, and provides treatments tailored to patients' needs. The unit emphasizes health education, supports breastfeeding, and encourages research initiatives for child health improvement. Plans for the future include extending coverage, vaccination services, and introducing baby/child welfare clinics."
        },
        {
            imgSrc: '/services/general_medicine.jpg',
            alt: 'general_medicine',
            icon: 'FaFlask',
            title: 'General Practitioner',
            desc: "The general practitioner at our clinic provides comprehensive care for all medical, surgical, pediatric, and other specialties throughout the year. Operating on a walk-in system, patients are attended to based on arrival, with immediate attention for emergencies. Upon arrival, patients undergo triage, and if deemed an emergency or urgent case, are referred directly to the appropriate doctor; otherwise, they wait for their turn. Routine cases involve a thorough history, examination, and necessary investigations, including external tests. Diagnoses are established, and appropriate treatments prescribed, with referrals to other specialties as needed. Emergency cases are efficiently managed in the absence of a specialist. Additionally, the general practitioner accompanies emergency cases during transfers, provides care for routine check-ups, and follows up with patients as required. There's no set limit on the number of patients seen, ensuring adequate time for each case based on need and the waiting list."
        },
        {
            imgSrc: '/services/Maxillofacial.jpg',
            alt: 'Maxillofacial',
            icon: 'FaTeeth',
            title: 'Specialist Oral & Maxillofacial Surgery',
            desc: "Oral Surgery includes the diagnosis, surgical and adjunctive treatment of diseases, injuries and defects involving both the functional and aesthetic aspects of the hard and soft tissues of the oral region."
        },
        {
            imgSrc: '/services/Orthodontics.jpg',
            alt: 'Orthodontics',
            icon: 'FaBone',
            title: 'Specialist Orthodontics',
            desc: "As a Specialist in Orthodontics, the role involves diagnosing and developing treatment plans for dentofacial anomalies and tooth/arch discrepancies. Coordination and close follow-up with other specialties are maintained throughout the treatment process. Prescribing and interpreting oral and maxillofacial x-ray images are fundamental aspects. The specialist corrects and prevents disharmonies of growth between upper and lower jaws, guides tooth development and positioning, and eliminates impairments in chewing ability caused by malocclusion. Addressing crowding to reduce susceptibility to dental decay and gum disease is essential. Harmful habits are eliminated, orofacial aesthetics are improved, and occlusion abnormalities are corrected. The specialist redirects growth, repositions jaws, and diagnoses and manages TMJ disorders. Utilizing various clear aligners for the movement or stabilization of dentofacial structures is also within the scope of a Specialist in Orthodontics."
        },
        {
            imgSrc: '/services/Periodontics.jpg',
            alt: 'Periodontics',
            icon: 'FaTeethOpen',
            title: 'Specialist Periodontics',
            desc: "As a Specialist in Periodontics, responsibilities encompass the establishment and maintenance of hard and soft tissues to support teeth and implants. This involves prescribing and interpreting oral and maxillofacial x-ray images. Evaluating occlusion and providing therapy options for periodontally involved teeth are crucial aspects. Procedures such as oral debridement, scaling, polishing, root planning, open flap debridement, and crown-lengthening are performed. Additionally, expertise extends to periodontal plastic surgery, aesthetic periodontal surgery, bone augmentation, Guided Tissue Regeneration (GTR), and soft tissue grafting. Specialized interventions include root resection, hemi-section, bicuspidization, and management of acute gingival and periodontal tissue infections. The placement of chemotherapeutic agents, adherence to legal prescription practices, and performing implant and peri-implant surgery procedures are integral to the role of a Specialist in Periodontics."
        },
        {
            imgSrc: '/services/dentistry.jpg',
            alt: 'dentistry',
            icon: 'FaTooth',
            title: 'General Dentistry',
            desc: "As a General Dentist, responsibilities encompass the check-up and diagnosis of various dento-facial lesions. This includes filling decayed teeth, performing dental prosthetic procedures such as crowns, bridges, and dentures, as well as root canal treatments. Basic oral surgical procedures like routine teeth extractions are part of the general dentistry scope. Additionally, the dentist provides cosmetic dental treatments like veneers and Lumineers. Routine oral hygiene maintenance such as scaling and polishing, along with teeth whitening procedures, falls within the purview of general dentistry."
        },

        {
            imgSrc: '/services/pediDe.jpg',
            alt: 'Pediatric dentistry',
            icon: 'FaChild',
            title: 'Specialist Pediatric Dentistry',
            desc: "As a Specialist Pediatric Dentist, responsibilities include the check-up and diagnosis of periodontal diseases in pediatric patients. This role extends to diagnosing and treating diseases of the pulpal and peri-radicular tissues. Management of the oral health of patients with special healthcare needs is a crucial aspect. The dentist also diagnoses abnormalities in developing dentition and provides appropriate treatment. Treatment approaches for pediatric patients encompass both non-pharmacological and pharmacological methods. Oral surgery procedures, such as teeth extractions, removal of cysts, frenectomy, and operculectomy, are performed. Additionally, the dentist specializes in the diagnosis and treatment of various dental and skeletal malocclusions using removable and fixed orthodontic appliances."
        },

        // ... (Add more entries for each unique speciality)
    ];

    const tabIndex = defaultSpeciality ? featuresData.findIndex(feature => feature.title === defaultSpeciality) : 0;
    return (


        <section id="departments" className="departments" style={{ marginTop: "100px" }}>
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>Specialities</h2>
                </div>
                <div className="row" data-aos="fade-up" data-aos-delay="100">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <ul className="nav nav-tabs flex-column">
                            {featuresData.map((feature, index) => (
                                <li className="nav-item" key={index}>
                                    <a className={`nav-link ${index === tabIndex ? 'active show' : ''}`} data-bs-toggle="tab" data-bs-target={`#tab-${index + 1}`}>
                                        <h4>{feature.title}</h4>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-lg-8">
                        <div className="tab-content">
                            {featuresData.map((feature, index) => (
                                <div key={index} className={`tab-pane ${index === tabIndex ? 'active show' : ''}`} id={`tab-${index + 1}`} >
                                    <Row>
                                        <img src={feature.imgSrc} alt="" className="img-fluid" style={{ maxWidth: "70%" }} />
                                    </Row>
                                    <h3>{feature.title}</h3>
                                    <p>{feature.desc}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}
export default Specialities