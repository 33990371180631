import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';



function SuccessModal({ show, handleClose }) {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Alert variant="success">
                    <Alert.Heading>Thank you</Alert.Heading>
                    <p>
                        Message sent, our team will contact you shortly
                    </p>

                </Alert>
            </Modal.Body>
        </Modal>
    )

}
export default SuccessModal