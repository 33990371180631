import { FaPhoneAlt } from "react-icons/fa";

const logo = process.env.PUBLIC_URL + '/Logo/NewLook.png';

function Branches({ onComponentChange }) {

    const branches = [
        { id: 1, name: 'NewLook Al Ain Souq Extra', image: '/Doctors/Majzoub.jpeg', address: 'A108 Adam Street, New York, NY 535022', email: ['contact@example.com', 'English'], number: "037222393" },
        { id: 2, name: 'NewLook Al Ain Al Yahar', image: '/Doctors/Majzoub.jpeg', address: 'A108 Adam Street, New York, NY 535022', email: ['contact@example.com', 'English'], number: "037666722" },
        { id: 3, name: 'NewLook Al AlKhabisi', image: '/Doctors/Majzoub.jpeg', address: 'A108 Adam Street, New York, NY 535022', email: ['contact@example.com', 'English'], number: "037636665" },


    ];
    return (
        <section id="featured-services" className="featured-services">
            <div className="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>Our Branches</h2>
                </div>

                <div className="row">
                    

                    <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" style={{ textAlign: "center" }} onClick={() => onComponentChange('contact', branches[0])}>
                        <div className="icon-box" data-aos="fade-up" data-aos-delay="200" style={{ width: "400px" }}>
                            <div className="icon"><img style={{ width: "150px" }} src={logo} alt="NewLook Al Ain Souq Extra"/></div>
                            <h4 className="title"><a href="">Al Ain Souq Extra</a></h4>
                            <div>
                                <p className="description"><FaPhoneAlt style={{ marginRight: "10px" }} />037222393</p>
                            </div>                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" style={{ textAlign: "center" }} onClick={() => onComponentChange('contact', branches[1])}>
                        <div className="icon-box" data-aos="fade-up" data-aos-delay="200" style={{ width: "400px" }}>
                            <div className="icon"><img style={{ width: "150px" }} src={logo} alt="NewLook Al Ain Al Yahar"/></div>
                            <h4 className="title"><a href="">Al Ain Al Yahar</a></h4>
                            <div>
                                <p className="description"><FaPhoneAlt style={{ marginRight: "10px" }} />037666722</p>
                            </div>                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0" style={{ textAlign: "center" }} onClick={() => onComponentChange('contact', branches[2])}>
                        <div className="icon-box" data-aos="fade-up" data-aos-delay="200" style={{ width: "400px" }}>
                            <div className="icon"><img style={{ width: "150px" }} src={logo} alt="NewLook Al Ain AlKhabisi"/></div>
                            <h4 className="title"><a href="">Al Ain AlKhabisi</a></h4>
                            <div>
                                <p className="description"><FaPhoneAlt style={{ marginRight: "10px" }} />037636665</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}
export default Branches