import { useState } from 'react';


function Slider() {

    const [activeSlide, setActiveSlide] = useState(0);

    const handlePrevClick = () => {
        setActiveSlide((prevSlide) => (prevSlide - 1 + 3) % 3); // Ensure the index wraps around
    };

    const handleNextClick = () => {
        setActiveSlide((prevSlide) => (prevSlide + 1) % 3); // Ensure the index wraps around
    };

    return (
        <section id="hero">
            <div id="heroCarousel" className="carousel slide carousel-fade" >

                <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

                <div className="carousel-inner" role="listbox">

                    {/* Slide 1 */}
                    <div className={`carousel-item ${activeSlide === 0 ? 'active' : ''}`} style={{ backgroundImage: "url(assets/img/slide/slide-1.jpg)" }}>
                        <div className="container">
                            <h2>Welcome to <span>New Look Medical Center</span></h2>
                            <p>Across all Newlook Medical Center’s branches, we pledge to provide extraordinary care with the latest technology and best practices in medicine. Our dedicated team of healthcare professionals is always here to meet your needs with compassion and expertise.</p>
                        </div>
                    </div>

                    {/* Slide 2 */}
                    <div className={`carousel-item ${activeSlide === 1 ? 'active' : ''}`} style={{ backgroundImage: "url(assets/img/slide/slide-2.jpg)" }}>
                        <div className="container">
                            <h2>Embark on a Wellness Voyage: Discover Exceptional Healthcare at Newlook Medical Center</h2>
                            <p>Join us on this journey to better health. Visit your nearest Newlook Medical Center branch and experience medical care that puts you first. Your health is our vision, and we’re here to ensure that you look and feel your best.</p>
                        </div>
                    </div>

                    {/* Slide 3 */}
                    <div className={`carousel-item ${activeSlide === 2 ? 'active' : ''}`} style={{ backgroundImage: "url(assets/img/slide/slide-3.jpg)" }}>
                        <div className="container">
                            <h2>Your Health, Our Vision</h2>
                            <p>Newlook Medical Center is a beacon of healthcare excellence in Al Ain, renowned for providing superior medical services with a patient-centered approach. We’re proud to be the leading medical center in the region with three state-of-the-art branches that cater to the diverse needs of our community.</p>
                        </div>
                    </div>

                </div>

                <a className="carousel-control-prev" role="button" onClick={handlePrevClick}>
                    <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
                </a>

                <a className="carousel-control-next" role="button" onClick={handleNextClick}>
                    <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
                </a>

            </div>
        </section>
    )

}
export default Slider