
import DoctorCard from "../DoctorCard/DoctorCard";
function HomeDoctors() {

    const allDoctors = [
        { id: 1, name: 'Dr. Amr Majzoub', image: '/Doctors/Majzoub.jpeg', speciality: 'Anesthesia', languages: ['Arabic', 'English'] },
        { id: 2, name: 'Dr. Atif Fahim Mina', image: '/Doctors/Fahim.jpeg', speciality: 'Anesthesia', languages: ['Arabic', 'English'] },
        { id: 3, name: 'Dr. Noor Sheema Ghazi', image: '/Doctors/Ghazi.jpeg', speciality: 'Anesthesia', languages: ['Arabic', 'English'] },
        { id: 4, name: 'Dr. Salim Taher Sherllalah', image: '/Doctors/Sherllalah.jpeg', speciality: 'Anesthesia', languages: ['English', 'Arabic'] },
        
    ];
    return (
        <section id="doctors" className="doctors section-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Doctors</h2>
                    <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                </div>

                <div className="row">
                    {allDoctors.map((doctor, index) => (
                        <DoctorCard props={doctor} index={index}/>
                    ))}
                </div>

            </div>
        </section>
    )

}
export default HomeDoctors