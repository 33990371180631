function Counts({ onComponentChange }) {
    const handleItemClick = (item) => {
        // Add logic here if needed
        // Call the onComponentChange function
        onComponentChange(item); // You can pass a parameter to identify which component to change
    };
    return (
        <section id="counts" className="counts">
            <div className="container" data-aos="fade-up">

                <div className="row no-gutters">
                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="far fa-hospital"></i>
                            <span data-purecounter-start="0" data-purecounter-end="26" data-purecounter-duration="1" className="purecounter">3</span>
                            <p><strong>Branches</strong></p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="fas fa-user-md"></i>
                            <span data-purecounter-start="0" data-purecounter-end="85" data-purecounter-duration="1" className="purecounter">57</span>

                            <p><strong>Doctors</strong></p>
                            <a href="#" onClick={() => handleItemClick('doctors')}>Find out more &raquo;</a>
                        </div>
                    </div>



                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="fas fa-flask"></i>
                            <span data-purecounter-start="0" data-purecounter-end="14" data-purecounter-duration="1" className="purecounter">11</span>
                            <p><strong>Specialities</strong> </p>
                            <a href="#" onClick={() => handleItemClick('Specialities')}>Find out more &raquo;</a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="fas fa-user"></i>
                            <span data-purecounter-start="0" data-purecounter-end="150" data-purecounter-duration="1" className="purecounter">524,167</span>
                            <p><strong>Patients</strong></p>
                            {/* <a href="#">Become one &raquo;</a> */}
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )

}
export default Counts