import logo from './logo.svg';
import './App.css';
import Slider from './Components/Slider/Slider';
import Home from './Components/Home/Home';
import NavBar from './Components/NavBar/NavBar';
import AboutUs from './Components/AboutUs/AboutUs';
import Specialities from './Components/Specialities/Specialities';
import React, { useState } from 'react';
import DoctorsList from './Components/DoctorsList/DoctorsList';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import FloatingMenu from './Components/FloatingMenu/FloatingMenu';


function App() {

  const [activeComponent, setActiveComponent] = useState('home');
  const [activeBranch, setActiveBranch] = useState(null);
  const [activeSpec, setActiveSpec] = useState(null);


  const [navBarKey, setNavBarKey] = useState(0);
  const handleComponentChange = (component, branch, spec) => {

    console.log(component)
    setActiveComponent(component);
    setActiveBranch(branch)
    setActiveSpec(spec)
    setNavBarKey(navBarKey => navBarKey + 1);
    console.log(spec)
    window.scrollTo(0,0)


  };

  
  return (
    <div>
      <NavBar onComponentChange={handleComponentChange} />

      {activeComponent === 'home' && <Home onComponentChange={handleComponentChange} />}
      {activeComponent === 'about' && <AboutUs onComponentChange={handleComponentChange} />}
      {activeComponent === 'Specialities' && <Specialities onComponentChange={handleComponentChange} defaultSpeciality={activeSpec}/>}
      {activeComponent === 'doctors' && <DoctorsList onComponentChange={handleComponentChange} />}
      {activeComponent === 'contact' && <Contact branch={activeBranch} />}


      <FloatingMenu />
      <Footer onComponentChange={handleComponentChange} />
    </div>
  );
}

export default App;
