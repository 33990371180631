function Footer({ onComponentChange }) {

    const branches = [
        { id: 1, name: 'NewLook Al Ain Souq Extra', image: '/Doctors/Majzoub.jpeg', address: 'Souq Extra - Al Bateen - Al Ghadeer - Al Ain', email: ['contact@example.com', 'English'], number: "03 716 3999" },
        { id: 3, name: 'NewLook Al Ain Al Yahar', image: '/Doctors/Majzoub.jpeg', address: 'Al Yahar - Al Aamerah - Al Ain', email: ['contact@example.com', 'English'], number: "03 766 6722" },
        { id: 4, name: 'NewLook Al AlKhabisi', image: '/Doctors/Majzoub.jpeg', address: 'AlKhabisi - Al Ain', email: ['contact@example.com', 'English'], number: "03 763 6665" },


    ];

    const handleButtonClick = (url) => {
        // Replace 'https://example.com' with the URL you want to open
        window.open(url, '_blank');
    };
    return (
        <footer id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-info">
                                <img style={{ width: "130px", height: "100%", objectFit: "cover" }} src="Logo/NewLook.png" alt="NewLook" />

                                <div className="social-links mt-3">
                                    <a onClick={() => handleButtonClick("https://www.facebook.com/NewlookMedicalCenter/")} href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                                    <a onClick={() => handleButtonClick("https://www.instagram.com/newlook_mc/?hl=en")} href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                                    <a onClick={() => handleButtonClick("https://wa.me/+97137163999")} href="#" className="instagram"><i className="bx bxl-whatsapp"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <a onClick={() => onComponentChange('home')} href="#">Home</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a onClick={() => onComponentChange('about')} href="#">About us</a></li>
                                {/* <li><i className="bx bx-chevron-right"></i> <a onClick={() => onComponentChange('home')} href="#">Services</a></li> */}
                                <li><i className="bx bx-chevron-right"></i> <a onClick={() => onComponentChange('Specialities')} href="#">Specialities</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a onClick={() => onComponentChange('doctors')} href="#">Doctors</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Our Branches</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <a onClick={() => onComponentChange('contact', branches[0])} href="#">NewLook Al Ain Souq Extra</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a onClick={() => onComponentChange('contact', branches[1])} href="#">NewLook Al Yahar</a></li>
                                <li><i className="bx bx-chevron-right"></i> <a onClick={() => onComponentChange('contact', branches[2])} href="#">NewLook AlKhabisi</a></li>
                            </ul>
                        </div>

                        {/* <div className="col-lg-4 col-md-6 footer-newsletter">
                            <h4>Our Newsletter</h4>

                            <form action="" method="post">
                                <input type="email" name="email" /><input type="submit" value="Subscribe" />
                            </form>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>NewLook</span></strong>. All Rights Reserved
                </div>

            </div> */}
        </footer>
    )

}
export default Footer