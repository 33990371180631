import Modal from 'react-bootstrap/Modal';
import { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

function Appointment({ show, handleClose, doctor, showPopUp }) {

    console.log(doctor)
    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [availableSpecialities, setAvailableSpecialities] = useState([]);
    

    useEffect(() => {
        // Check if doctor is defined
        if (doctor) {
            // If doctor is defined, assign values based on doctor's properties
            setSelectedSpeciality(doctor.speciality || '');
            setSelectedBranch(doctor.branch || '');
        } else {
            // If doctor is undefined, assign default values
            setSelectedSpeciality('');
            setSelectedBranch('');
        }
    }, [doctor]);


    const allDoctors = [
        { id: 1, name: 'Dr. Mamoun Al Athamneh', image: '/Doctors/Athamneh.JPG', branch: "Al Yahar", speciality: 'Consultant Dermatology', languages: ['Arabic', 'English'] },
        { id: 2, name: 'Dr. Mohamed Saad Alsebai', image: '/Doctors/Alsebai.jpeg', branch: "Al Yahar", speciality: 'Specialist Otolaryngology', languages: ['Arabic', 'English'] },
        { id: 3, name: 'Dr. Abdalla Eltayeb', image: '/Doctors/Eltayeb.JPG', branch: "Al Yahar", speciality: 'Specialist Pediatrics', languages: ['Arabic', 'English'] },
        { id: 4, name: 'Dr. Jeehan Mohammed', image: '/Doctors/Jeehan.JPG', branch: "Al Yahar", speciality: 'General Practitioner', languages: ['Arabic', 'English'] },
        { id: 5, name: 'Dr. Ali Sabouh', image: '/Doctors/Sabouh.jpg', branch: "Al Yahar", speciality: 'Specialist Oral & Maxillofacial Surgery', languages: ['Arabic', 'English'] },
        { id: 6, name: 'Dr. Mohamed Anwar', image: '/Doctors/Anwar.jpg', branch: "Al Yahar", speciality: 'Specialist Orthodontics', languages: ['Arabic', 'English'] },
        // { id: 7, name: 'Dr. Deepthi Puliyulla', image: '/Doctors/Puliyulla.JPG', branch: "Al Yahar" , speciality: 'Specialist Periodontics', languages: ['Arabic', 'English'] },
        { id: 8, name: 'Dr. Ehab Tageldin', image: '/Doctors/Tageldin.jpg', branch: "Al Yahar", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 9, name: 'Dr. Roaa Emad Yousif', image: '/Doctors/Emad.jpg', branch: "Al Yahar", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 10, name: 'Dr. Reham Ibrahim Ali', image: '/Doctors/Reham.jpg', branch: "Al Yahar", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 11, name: 'Dr. Wesam Mansour', image: '/Doctors/Wesam.jpg', branch: "AlKhabisi", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        { id: 12, name: 'Dr. Basel Mohammad', image: '/Doctors/Basel.jpg', branch: "AlKhabisi", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        { id: 13, name: 'Dr. Mifleh Khaled Ali', image: '/Doctors/Mifleh.jpg', branch: "AlKhabisi", speciality: 'General Practitioner', languages: ['Arabic', 'English'] },
        { id: 14, name: 'Dr. Pranjal Sharma', image: '/Doctors/Pranjal.jpg', branch: "AlKhabisi", speciality: 'Specialist Pediatric Dentistry', languages: ['Arabic', 'English'] },
        { id: 15, name: 'Dr. Adham Abu Kharroub', image: '/Doctors/Kharroub.jpg', branch: "AlKhabisi", speciality: 'Specialist Orthodontics', languages: ['Arabic', 'English'] },
        { id: 16, name: 'Dr. Al Thanzeer Usman', image: '/Doctors/Usman.jpg', branch: "AlKhabisi", speciality: 'Specialist Endodontics', languages: ['Arabic', 'English'] },
        { id: 17, name: 'Dr. Ahmed Khairi Izzat', image: '/Doctors/Izzat.jpg', branch: "AlKhabisi", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 18, name: 'Dr. Manar Abdul Fattah', image: '/Doctors/Fattah.jpg', branch: "AlKhabisi", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 19, name: 'Dr. Mohamad Termanini', image: '/Doctors/Termanini.jpg', branch: "AlKhabisi", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 20, name: 'Dr. Abdulrahman Lbabidi', image: '/Doctors/Lbabidi.jpg', branch: "AlKhabisi", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 21, name: 'Dr. Reiof Aman Hathat', image: '/Doctors/Reiof.jpg', branch: "AlKhabisi", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 22, name: 'Dr. Ahmed H S Ghazal', image: '/Doctors/Ghazal.jpg', branch: "AlKhabisi", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 23, name: 'Dr. Sami Owais', image: '/Doctors/Owais.jpg', branch: "Souq Extra", speciality: 'Specialist Dermatology', languages: ['Arabic', 'English'] },
        { id: 24, name: 'Dr. Osama H.M Qasrawi', image: '/Doctors/Qasrawi.jpg', branch: "Souq Extra", speciality: 'Specialist Orthodontics', languages: ['Arabic', 'English'] },
        { id: 25, name: 'Dr. Banan Shurrab', image: '/Doctors/Shurrab.jpg', branch: "Souq Extra", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 26, name: 'Dr. Huria Jarusheh', image: '/Doctors/Jarusheh.jpg', branch: "Souq Extra", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 27, name: 'Dr. Hind Hasan', image: '/Doctors/Hind.jpg', branch: "Souq Extra", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 28, name: 'Dr. Mohamed Hassan', image: '/Doctors/Mohamed.jpg', branch: "Souq Extra", speciality: 'General Dentist', languages: ['Arabic', 'English'] },
        { id: 29, name: 'Dr. Abdulrahman Mohammed', image: '/Doctors/Abdulrahman.jpg', branch: "Souq Extra", speciality: 'General Dentist', languages: ['Arabic', 'English'] },



    ];

    const specialities = Array.from(new Set(allDoctors.map((doctor) => doctor.speciality)));

    const filteredDoctors = allDoctors.filter((doctor) => {
        return (!selectedSpeciality || doctor.speciality === selectedSpeciality) &&
            (selectedLanguages.length === 0 ||
                selectedLanguages.includes('All') ||
                selectedLanguages.some(language => doctor.languages.includes(language))) &&
            (!selectedBranch || doctor.branch === selectedBranch);
    });

    const handleSpecialityChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSpeciality(selectedValue);
    };

    const handleBranchChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedBranch(selectedValue);
        setSelectedSpeciality(''); // Clear selected specialty

        // Get the specialties for the selected branch
        const specialtiesForBranch = allDoctors
            .filter(doctor => doctor.branch === selectedValue)
            .map(doctor => doctor.speciality);

        // Set the available specialties based on the selected branch
        setAvailableSpecialities(Array.from(new Set(specialtiesForBranch)));
    };

    function sendEmail(e) {

        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it
        //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fwgrqew', 'template_4v62mp8', e.target, 'tKbfD7Qc7HH_s5Z5R')
            .then((result) => {
                // window.location.reload()  
                successAppointment()
            }, (error) => {
                console.log(error.text);
            });

    }

    function successAppointment() {

        showPopUp()
        handleClose()

    }

    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        let dateInput = document.getElementById("date");
        if (dateInput) {
            dateInput.min = new Date().toISOString().slice(0, new Date().toISOString().lastIndexOf(":"));
        }
    }, []);

    return (
        <Modal show={show} onHide={handleClose} className='modal-xl'>
            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                <section id="appointment" className="appointment section-bg">
                    <div className="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2>Make an Appointment</h2>
                        </div>

                        <form onSubmit={sendEmail} role="form" className="php-email-form">
                            <div className="row">
                                <div className="col-md-4 form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                </div>
                                <div className="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                </div>
                                <div className="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="tel" className="form-control" name="phone" id="phone" placeholder="Your Phone" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 form-group mt-3">
                                    <select name="branch" id="department" className="form-select" value={selectedBranch} onChange={handleBranchChange}>
                                        <option value="">Select Branch</option>
                                        <option value="Souq Extra">NewLook Al Ain Souq Extra</option>
                                        <option value="Al Yahar">NewLook Al Ain Al Yahar</option>
                                        <option value="AlKhabisi">NewLook Al AlKhabis</option>
                                    </select>
                                </div>
                                <div className="col-md-4 form-group mt-3">
                                    <select name="service" id="department" className="form-select" value={selectedSpeciality} onChange={handleSpecialityChange}>
                                        {doctor ? (
                                            // If doctor prop is defined, show a single option with the doctor's speciality
                                            <option value={doctor.speciality}>
                                                {doctor.speciality}
                                            </option>
                                        ) : (
                                            // If doctor prop is undefined, render options based on availableSpecialities
                                            <>
                                                <option value="">Select Speciality</option>
                                                {availableSpecialities.map((speciality, index) => (
                                                    <option key={index} value={speciality}>
                                                        {speciality}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </select>
                                </div>
                                <div className="col-md-4 form-group mt-3">
                                    <select name="doctor" id="doctor" className="form-select">
                                        {doctor ? (
                                            // If doctor prop is defined, show a single option with the doctor's name
                                            <option value={doctor.name}>
                                                {doctor.name}
                                            </option>
                                        ) : (
                                            // If doctor prop is undefined, render options based on filteredDoctors
                                            filteredDoctors.map((doc, index) => (
                                                <option key={index} value={doc.name}>
                                                    {doc.name}
                                                </option>
                                            ))
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 form-group mt-3">
                                    <input type="datetime-local" name="date" className="form-control datepicker" id="date" placeholder="Appointment Date" required />
                                </div>
                                <div className="col-md-4 form-group mt-3 mt-md-0">
                                    <input type="hidden" className="form-control" name="sendTarget" value="info@newlook.ae" id="test" placeholder="Your Phone" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                    <div className="validate"></div>
                                </div>

                            </div>
                            <div className="form-group mt-3">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message (Optional)"></textarea>
                            </div>
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your appointment request has been sent successfully. Thank you!</div>
                            </div>
                            <div className="text-center"><button type="submit">Make an Appointment</button></div>
                        </form>
                    </div>
                </section>
            </Modal.Body>

        </Modal>
    )

}
export default Appointment