import { FaCheckCircle } from "react-icons/fa";
import { Container, Row, Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap

function Features() {

    const branches = [
        {
            id: 2, name: "New Look Al Ain Souq Extra Branch",
            desc: "Our Souq Extra branch specializes in bringing out the beauty in you. Here, you can take advantage of:",
            services: [
                "Dermatology: Advanced care for healthy, glowing skin.",
                "Dental: Comprehensive dental services for impeccable oral health.",
                "Skin Care and Facial Treatments: Nourish your skin with personalized treatments.",
                "Laser Hair Removal: Experience smooth, hair-free skin with our state-of-the-art laser technology."

            ],
            image:"/Branches/NewLookExtraMall.jpg"
        },
        {
            id: 3, name: "New Look Al Ain Al Yahar Branch",
            desc: "The Newlook Medical Center's Al Yahar branch stands poised to become the largest outpatient facility in the area, serving communities from Al Khanna to Al Salamat. With the latest expansion, we are thrilled to offer an all-encompassing array of medical services:",
            services: [
                "Orthopedics: Expert care for your musculoskeletal system.",
                "Pediatrics: Compassionate care for your children’s health and development.",
                "Obstetrics and Gynecology: Exceptional care for women through all stages of life.",
                "ENT: Cutting-edge solutions for ENT disorders.",
                "Family Medicine: Personalized healthcare for patients of all ages.",
                "Nutrition: Scientifically-backed nutrition advice.",
                "General Practitioner (GP): The starting point for all your health and wellness needs.",
                "Dental: Modern dentistry for optimal oral care.",
                "Facial and Skin Care: Rejuvenate your appearance with our specialized treatments.",
                "Slimming and Body Care: Transformative body shaping and weight loss services."


            ],
            image:"/Branches/YaharTemp.jpg"
        },
        {
            id: 4, name: "New Look Al Ain AlKhabisi Branch",
            desc: "At our Al Khabisi branch, your well-being is our priority. We offer a wide range of medical services under one roof, ensuring convenience and comprehensive care. Our expert team of specialists is dedicated to your health in various fields including:",
            services: [
                "Pediatrics: Providing tender care for your little ones.",
                "Obstetrics and Gynecology: Expertise in women's health, prenatal and postnatal care.",
                "ENT: Advanced treatment for ear, nose, and throat conditions.",
                "Family Medicine: Wholesome care for the entire family.",
                "Nutrition: Tailored dietary plans for optimal well-being.",
                "General Practitioner (GP): Quality healthcare services for all general medical needs.",
                "Dental: Cutting-edge dental treatments for a confident smile.",
                "Facial and Skin Care: Revitalize your skin with our expert dermatological services.",
                "Slimming and Body Care: Innovative solutions for body contouring and weight management.",


            ],
            image: "/Branches/NewLookKhabisi.jpg"
        }
    ]

    return (
        <div>
            {branches.map((branch, index) => (
                <section
                    id="features"
                    className="features"
                    key={branch.id}
                >
                    <div
                        className="container"
                        data-aos="fade-up"
                    >
                        <div className="row">
                            <div
                                className={`col-lg-6 order-${index % 2 === 0 ? '2' : '1'} order-lg-${index % 2 === 0 ? '1' : '2'}`}
                                data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}
                            >
                                <div className="icon-box mt-5 mt-lg-0">
                                    <i className="far fa-hospital" style={{ fontSize: "35px" }}></i>
                                    <h4>{branch.name}</h4>
                                    <p style={{ color: "black" }}>{branch.desc}</p>


                                    {branch.services && branch.services.map((service, index) => {
                                        const [title, description] = service.split(':');
                                        return (
                                            <p key={index} style={{ color: "black" }}>
                                                <FaCheckCircle style={{ marginRight: "10px" }} />
                                                <strong>{title.trim()}</strong>: {description.trim()}
                                            </p>
                                        );
                                    })}


                                </div>
                            </div>
                            <div
                                className={`image col-lg-6 order-${index % 2 === 0 ? '1' : '2'} order-lg-${index % 2 === 0 ? '2' : '1'}`}
                                style={{ backgroundImage: `url("${branch.image}")` }}
                                data-aos="zoom-in"
                            ></div>

                        </div>
                    </div>
                </section>
            ))}
        </div>
    )

}
export default Features